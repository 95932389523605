<template>
  <div v-loading="true" style="width: 100vw;height: 100vh"></div>
</template>

<script>
export default {
  name: 'oauth',
  computed: {
    accessToken() {
      return this.$route.query.jwt
    },
    error() {
      return this.$route.query.error
    }
  },
  mounted() {
    if (!this.error && this.accessToken) {
      this.$store.commit('login', { accessToken: this.accessToken })
      localStorage.removeItem('userInfo')
    } else if (this.error.match('User has been signed up with another provider')) {
      this.$notify.error({ title: 'Ошибка', message: `Пользователь с такой почтой был зарегистрирован через: ${this.error.split(':').pop()}` })
    } else {
      this.$notify.error({ title: 'Ошибка', message: 'Что-то пошло не так' })
    }
    this.$router.push('/profile')
  },
}
</script>

<style scoped lang="scss"></style>
